import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react'; 
import '@aws-amplify/ui-react/styles.css';

import { listSongs } from './graphql/queries';

Amplify.configure(awsconfig)

function App() {
const [songs, setSongs] = useState([]);

useEffect(() => {
  fetchSongs();
}, []);

const fetchSongs = async () => {
  try {
    const songData = await API.graphql(graphqlOperation(listSongs))
    const songList = songData.data.listSongs.items;

    console.log('song list', songList);
    setSongs(songList);
  } catch(error) {
    console.log("error on fetching songs", error);
  }
}

  return (
    <div className="App">
      <header className="App-header">
        <Authenticator>
          {({ signOut, user }) => (
            <div className="App">
              <p>
                Welcome to MindFiend space
              </p>
              <button onClick={signOut}>Sign out</button>
            </div>
          )}
        </Authenticator>
        <h2>My App Content</h2>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
